// Horizontal
@media screen and (max-width: 1024px) {
  .header {
    &-logo {
      & img {
        height: 60px;
      }
    }
  }

  #home {
    & .top-block {
      & .description {
        font-size: 3em;

        & .badges {
          & img {
            height: 45px;
          }
        }
      }

      & .previews {
        & .android-phone-preview {
          height: 60vh;
        }
      }
    }
  }

  #about {
    & .content {
      & .block {
        & img {
          height: 40vh;
        }
      }

      & .general-block {
        min-height: 30vh;

        & .description {
          padding-top: 15vh;
          min-height: 30vh;
        }
      }
    }
  }
}

// Vertical
@media screen and (max-width: 768px) {
  body {
    font-size: 0.9em;
  }

  .header {
    &-logo {
      & img {
        height: 45px;
      }
    }
  }

  #home {
    & .top-block {
      & .description {
        font-size: 2.5em;

        & .badges {
          & img {
            height: 35px;
          }
        }
      }

      & .previews {
        & .android-phone-preview {
          height: 40vh;
        }
      }
    }
  }

  #about {
    & .content {
      & .block {
        & img {
          height: 30vh;

          &.mobile-hidden {
            display: none;
          }
        }
      }

      & .general-block {
        min-height: 20vh;

        & .description {
          padding-top: 10vh;
          min-height: 20vh;
        }
      }
    }
  }

  #team {
    & .content {
      & .block {
        min-height: 20vh !important;

        & .description {
          min-height: 20vh !important;

          & .pic {
            margin: 0 !important;
            width: 15vh !important;
            height: 15vh !important;
            box-shadow: none !important;
          }
        }
      }

      & .right-block {
        & .description {
          justify-content: space-evenly !important;
        }
      }

      & .left-block {
        & .description {
          justify-content: space-evenly !important;
        }
      }

      & .damien-block {
        min-height: 30vh !important;

        & .description {
          padding-top: 10vh !important;
          min-height: 30vh !important;
        }
      }
    }
  }
}
