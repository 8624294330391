#incoming {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $textColor;

  & .content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    min-width: 100%;
    background: $lightGrey;
  }
}
