#home {
  display: flex;
  background: url('../../images/home/top_block_bg.jpg') no-repeat center center
    fixed;
  background-size: cover;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .top-block {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      90deg,
      rgba(33, 33, 33, 1) 20%,
      rgba(33, 33, 33, 0.9) 70%,
      rgba(0, 0, 0, 0) 100%
    );
    min-height: 100vh;
    width: 100%;
    padding: 5vh;
    padding-top: 20vh;
    margin-bottom: 0;

    & .description {
      font-size: 4em;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      & p {
        margin: 0;
      }

      & .badges {
        display: flex;
        flex-direction: column;

        & .badge {
          margin-top: 20px;

          & a {
            display: flex;
            align-items: center;
            font-size: 0.3em;
            color: $textColor;
            margin-bottom: 10px;

            & img {
              height: 45px;
              margin: 0 10px;
              border: 2px $extraLightGrey solid;
              border-radius: 10px;
            }
          }
        }
      }
    }

    & .previews {
      & .android-phone-preview {
        height: 60vh;
      }
    }
  }
}
