.footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  background: $darkGrey;
  margin-bottom: 0;
  min-height: 10vh;

  & a {
    color: inherit;
  }

  & .badges {
    display: flex;

    & .googlePlay {
      & img {
        height: 40px;
        margin-right: 10px;
        border: 2px $extraLightGrey solid;
        border-radius: 10px;
      }
    }

    & .paypalDonate {
      border: none;

      & img {
        border: none;
      }
    }
  }

  & .social-medias {
    & a {
      margin: 10px;
      font-size: 2em;
    }
  }
}
