@import './mixins.scss';
@import './vars.scss';
@import './partials/about.scss';
@import './partials/footer.scss';
@import './partials/header.scss';
@import './partials/home.scss';
@import './partials/incoming.scss';
@import './partials/navbar.scss';
@import './partials/smartphone.scss';
@import './partials/tablet.scss';
@import './partials/team.scss';

.App {
  background-color: $darkGrey;
  color: $textColor;
  min-height: 100vh;
}
