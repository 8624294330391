$extraLightGrey: #eeeeee;
$lighterGrey: #aaa;
$lightGrey: #616161;
$darkGrey: #212121;
$darkestGrey: #191919;
$lightSheetBgColor: #4589b0;
$darkSheetBgColor: #295169;
$lightSetListBgColor: #2a9674;
$darkSetListBgColor: #144737;
$textColor: $extraLightGrey;
