.header {
  & .nav {
    background: transparent;
    box-shadow: none;
    color: $textColor;
    font-weight: bold;

    &.hidden {
      animation: slide 0.5s forwards;
      animation-delay: 2s;
      display: none;
    }

    & ul {
      & li {
        margin-left: 10%;

        & a {
          padding: 0 10px;

          &:hover {
            border-radius: 50px;
            animation: lightpulse 1s infinite;
            transform: scale(1);
          }
        }
      }
    }
  }
}

@keyframes slide {
  100% {
    right: 0;
  }
}
