.header {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  align-items: center;

  &.scrolled {
    display: block;
  }

  &-logo {
    padding: 20px;
    transition: padding 200ms ease-in;

    &.small {
      animation: intenselightpulse 1s infinite;
      border-radius: 120px;
      padding: 0;
      transition: padding 200ms ease-in;
      cursor: pointer;

      & img {
        transform: scale(0.5);
        transition: all 200ms ease-in;
        justify-content: flex-start;
      }
    }

    & img {
      height: 100px;
      transform: scale(1);
      transition: all 200ms ease-in;
    }

    &:hover {
      border-radius: 120px;
      animation: intenselightpulse 1s infinite;
      transform: scale(1);
    }
  }
}
