#about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $textColor;

  & .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100%;
    background: $darkGrey;

    & .block {
      width: 100%;
      min-height: 50vh;
      margin-bottom: 0;

      & .description {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 50vh;
        width: 100%;
        padding: 5vh 10vw;
        margin-bottom: 0;
      }

      & img {
        height: 60vh;
      }
    }

    & .general-block {
      & .description {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-top: 20vh;

        & p {
          margin-bottom: 0;
        }
      }
    }

    & .metronome-block {
      background: url('../../images/about/metronome.jpg') no-repeat top right
        fixed;
      background-size: 100% 100%;

      & .description {
        background: linear-gradient(
          90deg,
          rgba(33, 33, 33, 1) 20%,
          rgba(33, 33, 33, 0.9) 50%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    & .sheets-block {
      background: url('../../images/about/sheet.jpg') no-repeat top left fixed;
      background-size: 80% 100%;

      & .description {
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(69, 137, 176, 0.9) 50%,
          rgba(41, 81, 105, 1) 80%
        );
      }
    }

    & .setlists-block {
      background: url('../../images/about/setlist.jpg') no-repeat top right
        fixed;
      background-size: 80% 100%;

      & .description {
        background: linear-gradient(
          90deg,
          rgba(20, 71, 55, 1) 20%,
          rgba(42, 150, 116, 0.9) 50%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
  }
}
