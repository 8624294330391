#team {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $extraLightGrey;

  & .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100%;
    background: $darkGrey;

    & .block {
      width: 100%;
      min-height: 50vh;
      margin-bottom: 0;

      & .description {
        display: flex;
        align-items: center;
        min-height: 50vh;
        width: 100%;
        padding: 5vh 10vw;
        margin-bottom: 0;

        & .desc {
          display: inherit;
          flex-direction: column;
          align-items: center;

          & h2 {
            font-size: 2em;
          }

          & .social-medias {
            & a {
              color: inherit;
            }

            & i {
              margin: 0 2vw;
            }
          }
        }

        & .pic {
          margin: 0 20vh;
          width: 20vh;
          height: 20vh;
          box-shadow: 0 0 8px 8px $darkGrey inset;
        }
      }
    }

    & .right-block {
      & .description {
        justify-content: flex-end;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(33, 33, 33, 0.9) 50%,
          rgba(33, 33, 33, 1) 70%
        );
      }
    }

    & .left-block {
      & .description {
        justify-content: flex-start;
        background: linear-gradient(
          90deg,
          rgba(33, 33, 33, 1) 30%,
          rgba(33, 33, 33, 0.9) 50%,
          rgba(0, 0, 0, 0) 100%
        );

        & desc {
          align-items: flex-start;
        }
      }
    }

    & .damien-block {
      background: url('../../images/team/damien_bg.jpg') no-repeat top left
        fixed;
      background-size: cover;
      min-height: 70vh;

      & .description {
        padding-top: 20vh;
        min-height: 70vh;

        & .pic {
          background: url('../../images/team/damien.jpg') no-repeat center
            center;
          background-size: contain;

          &:hover {
            animation: spinner 1s infinite;
          }
        }
      }
    }

    & .thomas-block {
      background: url('../../images/team/thomas_bg.jpg') no-repeat top right
        fixed;
      background-size: cover;

      & .description {
        & .pic {
          background: url('../../images/team/thomas.jpg') no-repeat center
            center;
          background-size: contain;

          &:hover {
            animation: heartbeat 0.2s infinite;
          }
        }
      }
    }

    & .marine-block {
      background: url('../../images/team/marine_bg.jpg') no-repeat top left
        fixed;
      background-size: cover;

      & .description {
        & .pic {
          background: url('../../images/team/marine.jpg') no-repeat center
            center;
          background-size: contain;

          &:hover {
            animation: shake 0.5s infinite;
          }
        }

        & .desc {
          & a {
            color: $lightSetListBgColor;
          }
        }
      }
    }
  }
}
