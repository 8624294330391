// Horizontal
@media screen and (max-width: 600px) {
  .header {
    &-logo {
      & img {
        height: 60px;
      }
    }
  }

  .footer {
    & .badges {
      & img {
        height: 30px;
      }
    }

    & .social-medias {
      & a {
        margin: 10px;
        font-size: 1em;
      }
    }
  }

  #about {
    & .content {
      & .block {
        & img {
          height: 40vh;
        }
      }

      & .general-block {
        & .description {
          padding-top: 20vh !important;
        }
      }
    }
  }
}

// Vertical
@media screen and (max-width: 480px) {
  .header {
    &-logo {
      width: 100%;

      &.small {
        width: 10vh;
        padding: 0 !important;
      }
    }

    & .nav {
      & ul {
        & li {
          margin-left: 2%;

          & a {
            font-size: 0.8em;
          }
        }
      }
    }
  }

  #home {
    & .top-block {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .description {
        font-size: 1.5em !important;

        & .badges {
          & img {
            height: 30px !important;
          }
        }
      }
    }
  }

  #about {
    & .block .description {
      flex-direction: column;
    }
  }

  .footer {
    font-size: 0.7em;
    min-height: 20vh;
    padding: 0 5px;

    & .social-medias {
      & a {
        font-size: 2em;
      }
    }

    & .badges {
      align-items: center;
      flex-direction: column;
    }
  }
}
